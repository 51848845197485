import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BootstrapButton = styled(Button)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: ctx.theme.palette.common.black,
    color: '#fff',
    '&:disabled': {
        backgroundColor: ctx.theme.palette.grey[700],
        boxShadow: 'none',
    },
    '&:hover': {
        backgroundColor: ctx.theme.palette.primary.main,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}))

export const GradientButton = styled(Button)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: ctx.theme.palette.common.black,
    backgroundImage: ctx.theme.palette.primaryGradient,
    color: '#fff',
    '&:hover': {
        backgroundColor: ctx.theme.palette.common.black,
        boxShadow: 'none',
        backgroundImage: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}))
