import { Badge, Fab } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { ChatBubble } from './chat/ChatBubble'
import { ChannelProvider, useChannel } from 'ably/react'
import { useUserSession } from '@/hooks/useUserSession'
import { PoolChatMessage } from '@/utils/pool_types'
import { differenceInMinutes } from 'date-fns'

type ChatFABProps = {
    poolCode: string
}

export const ChatFAB: FC<ChatFABProps> = (props) => {
    const { poolCode } = props

    return (
        <ChannelProvider channelName={`footory-chat-${poolCode}`}>
            <ChatFABInner {...props} />
        </ChannelProvider>
    )
}

const ChatFABInner: FC<ChatFABProps> = (props) => {
    const { poolCode } = props
    const [showChat, setShowChat] = useState(false)
    const [showNewMessageBadge, setShowNewMessageBadge] = useState(false)
    const [session] = useUserSession()
    const [lastNewMessageNotification, setLastNewMessageNotification] = useState<Date | null>(null)

    useChannel(`footory-chat-${poolCode}`, 'chat-message-added', async (message) => {
        const newChatMessage = message.data as PoolChatMessage

        if (newChatMessage.user.id !== session?.user?.userXRefId) {
            setShowNewMessageBadge(true)

            if (
                Notification &&
                Notification.permission !== 'denied' &&
                (lastNewMessageNotification === null ||
                    differenceInMinutes(lastNewMessageNotification, new Date()) > 1)
            ) {
                if (Notification.permission === 'granted') {
                    notifyNewMessage()
                } else if (Notification.permission === 'default') {
                    const permission = await Notification.requestPermission()

                    if (permission === 'granted') {
                        notifyNewMessage()
                    }
                }
            }
        }
    })

    const notifyNewMessage = () => {
        setLastNewMessageNotification(new Date())
        new Notification('Nouveau message chat!', { body: 'Il y a un nouveau message dans le chat.' })
    }

    useEffect(() => {
        if (showChat) {
            setShowNewMessageBadge(false)
        }
    }, [showChat])

    return (
        <>
            {!showChat && (
                <Fab
                    onClick={() => setShowChat(true)}
                    sx={{ position: 'fixed', right: 20, bottom: 20 }}
                    color="secondary"
                    aria-label="chat"
                >
                    {showNewMessageBadge ? (
                        <Badge variant="dot" color="error">
                            <ChatBubbleIcon />
                        </Badge>
                    ) : (
                        <ChatBubbleIcon />
                    )}
                </Fab>
            )}
            {showChat && <ChatBubble onClose={() => setShowChat(false)} poolCode={poolCode} />}
        </>
    )
}
